@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html,
body,
#main {
  background-color: #000;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #d4d4d4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
}

body,
input,
textarea,
select {
  min-height: 36px;
  font-size: 18px;
  font-family: 'Archivo', sans-serif;
}

input.email,
.name-entry-input {
  min-width: 300px;
}

input.url {
  min-width: 420px;
}

button {
  padding: 12px 24px; /* Ensure button has proper padding */
  margin-left: auto; /* Push button to the far right */
  background-color: #4ea64c; /* Set background color */
  color: #d4d4d4; /* Set text color */
  border: none; /* Remove border */
  border-radius: 8px; /* Add border radius */
  cursor: pointer; /* Change cursor to pointer */
  font-size: 16px; /* Set font size */
  font-family: 'Inter', sans-serif; /* Use Inter font */
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s; /* Add transition for smooth hover effect */
}

button:disabled {
  background-color: #555; /* Change background color when disabled */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

button:hover,
a:hover,
.close-button:hover {
  border-radius: 8px;
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.social-media a {
  font-family: sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 36px;
  will-change: var(--framer-will-change-override, transform);
  border-bottom-width: 2px;
  border-color: rgba(106, 108, 118, 1);
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  background: linear-gradient(-45deg, rgb(79, 80, 84) 0%, rgb(94, 97, 109) 100%);
  padding: 4px;
  height: 36px;
  border-radius: 4px;
  opacity: 1;
}

.social-media a:hover,
.social-media a:focus {
  border-color: rgba(212, 212, 212, 1);
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tabs-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  opacity: 1;
  display: flex;
  min-height: 60px;
  flex-direction: column;
  align-items: center;
  background-color: #000; /* needed together with z-index to cover scrollable content */
}

.inner-tabs-container {
  width: 100%;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  min-height: 75px;
}

.menu img {
  height: 65px;
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits within its container */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute; /* Position it absolutely within .menu */
  top: 0; /* Align to the top of .menu */
  right: 0; /* Align to the right of .menu */
  z-index: 1001; /* Ensure it's above other content */
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

a:link {
  color: #add8e6;
}

a:visited {
  color: #add8e6;
}

.tabs {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.tabs a {
  padding: 10px 15px;
  text-decoration: none;
  color: white;
}

.tabs .title {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.tabs a,
.menu a {
  display: inline-block; /* This makes the anchor behave like a button */
  text-decoration: none;
}

.tabs a,
.title {
  font-size: 17px;
  font-family: 'Archivo', sans-serif;
  background: none;
  border: none;
  color: #d4d4d4;
  font-size: 1rem;
  margin: 5px 0px 0px 0px;
  padding: 10px;
  cursor: pointer;
  outline: none;
  transition: color 0.3s, border-bottom 0.3s;
  text-transform: uppercase;
}

a.title {
  color: #d4d4d4;
}

.tabs a:hover,
.social-media a:hover {
  color: #fff;
  background-color: #555; /* Change background color on hover */
  transform: scale(1.05);
}

.tabs a:focus {
  outline: none;
  color: #fff;
}

.tabs a.active {
  color: #fff;
}

.content {
  margin-top: 80px;
  overflow-y: auto;
  flex: 1;
  display: flex; /* Add flex display */
  flex-direction: column; /* Ensure items stack vertically */
  align-items: center; /* Center items horizontally */
}

.modal-content {
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
  width: 100%;        /* Ensure it uses available width */
  max-width: 600px;   /* Set a reasonable maximum width */
  margin: 20px;
  overflow: auto;
}

.container-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.social-media-container {
  /* This is for both "View Details" and "Thank you" pages */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.social-media {
  display: inline-flex;
  align-items: center;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-media-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.modal-overlay {
  width: 95vw;       /* Set width to viewport width */
  max-width: 100vw;    /* Ensure it doesn't exceed viewport width */
  overflow-x: hidden;  /* Hide horizontal overflow */
  background: rgba(0, 0, 0, 0.8); /* Optional: semi-transparent background */
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.modal-button {
  margin: 10px;
}

.close-button {
  align-self: flex-start;
  width: 36px;
  height: 36px;
  border: none;
  cursor: pointer;

  background: 
    no-repeat 
    center 
    / contain 
    url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20256%22%20fill%3D%22%23d4d4d4%22%3E%3Cpath%20d%3D%22M205.66%2C194.34a8%2C8%2C0%2C0%2C1-11.32%2C11.32L128%2C139.31%2C61.66%2C205.66a8%2C8%2C0%2C0%2C1-11.32-11.32L116.69%2C128%2C50.34%2C61.66A8%2C8%2C0%2C0%2C1%2C61.66%2C50.34L128%2C116.69l66.34-66.35a8%2C8%2C0%2C0%2C1%2C11.32%2C11.32L139.31%2C128Z%22%20%2F%3E%3C%2Fsvg%3E");
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  width: 100%;
  height: 100px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  color: rgb(212, 212, 212);
  user-select: none;
  gap: 4px;
  white-space: nowrap;
  padding: 0 20px; /* Add some padding to the sides */
  box-sizing: border-box; /* Ensure padding is included in total width */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Below iframe */
  padding: 200px 0 0 0;
  font-size: large;
}

ul li,
ol li {
  margin-bottom: 20px;
}

.carousel-container {
  max-width: 1000px; /* Adjust to the desired width */
  width: 100%; /* Adjust to the desired width */
  margin: 0 auto; /* Centers the carousel */
  height: auto; /* Adjust to the desired height */
  position: relative;
}

.carousel-item {
  overflow: hidden; /* Hide any content that exceeds the height */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Make sure elements are spaced */
  align-items: center;
  margin: 10px;
}

.carousel-item a {
  display: inline-block; /* Ensure the anchor wraps around the image */
  text-decoration: none; /* Remove default underline */
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.fixed-width-visual {
  display: block;
  width: 100%;
  height: auto;
}

.custom-carousel-arrow {
  position: absolute;
  top: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-family: Arial, sans-serif;
  border: none;
  /* background: green; */
  cursor: pointer;
  padding: 0;
  margin: 0;

  /* Flexbox properties for centering */
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
}

.request-signature-box {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap as needed */
  width: 100%;
  margin: 20px;
}

.request-signature-box p {
  max-width: 100%; /* Set the maximum width of the div */
  word-wrap: break-word; /* Ensure long text inside a div wraps */
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

@media (max-width: 768px) {
  .tabs {
    display: none;
    flex-direction: column;
  }

  .tabs.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu img {
    height: 33px; /* Half of the original height */
    width: auto; /* Keep the width proportional */
  }
}
